import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";

const LoginModal = ({ open, onClose }) => {
  return (
    <Modal isOpen={open} toggle={onClose} centered>
      <ModalHeader toggle={onClose}>Kindly Login First</ModalHeader>
      <ModalBody>
        <p>
          To connect with the property owner via Call and WhatsApp, please log
          in first.
        </p>
        <div className="text-center mt-3">
          <Button
            color="primary"
            onClick={() => {
              onClose();
              window.location.href = "/login"; 
            }}
          >
            Login
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default LoginModal;

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import size from '../Assets/Screenshot_2024-11-28_123540-removebg-preview.png';
import size1 from '../Assets/person.png';
import size2 from '../Assets/room.png';

// Import loader styles or an image if you have one
import Loader from '../Components/Loader'; // Assuming you have a Loader component or import a spinner image

function AllCollections() {
  const { locationid, collectionid } = useParams();
  const [hotels, setHotels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
  
  const handleNavigation = (path) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(path);
  };

  useEffect(() => {
    const fetchHotels = async () => {
      try {
        setLoading(true); // Start loader
        let apiUrl = 'https://tripolo-backend.vercel.app/api/hotel';

        if (locationid && collectionid) {
          apiUrl = `${apiUrl}/location/${locationid}/collection/${collectionid}`;
        } else if (collectionid) {
          apiUrl = `${apiUrl}/collection/${collectionid}`;
        }

        const response = await axios.get(apiUrl);
        if (response?.data?.data) {
          const shuffledHotels = shuffleArray(response.data.data);
          setHotels(shuffledHotels);
        } else {
          setError('No hotels found for the selected location and collection.');
        }
      } catch (err) {
        setError('Error fetching hotels. Please try again later.');
        console.error(err);
      } finally {
        setLoading(false); // End loader
      }
    };

    fetchHotels();
  }, [locationid, collectionid]);

  const shuffleArray = (array) =>
    array
      .map((item) => ({ ...item, sortKey: Math.random() }))
      .sort((a, b) => a.sortKey - b.sortKey)
      .map(({ sortKey, ...rest }) => rest);

  const handleSearchChange = (event) => setSearchQuery(event.target.value);

  const filteredHotels = hotels.filter((hotel) =>
    hotel.propertyName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleCardClick = (id) => navigate(`/collectiondetail/${id}`);

  return (
    <>
      <Navbar />
      <div className="col-banner col-banner2">
        <div className="search-bar search-bar2">
          <h2>All Collections</h2>
          <div className="search-bar-div">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search Collection"
            />
            <i className="bi bi-search"></i>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="loader-container">
          <Loader /> {/* Custom loader component */}
        </div>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : (
        <div className="trending-cards2 trending-cards3">
          <div className="az">
            {filteredHotels.map((hotel) => (
              <div
                key={hotel._id}
                className="trending-card trending-card2"
                onClick={() => handleCardClick(hotel._id)}
                style={{ cursor: 'pointer' }}
              >
                <img src={hotel.images.profile} alt={hotel.propertyName} />
                <div className="trending-text">
                  <h3>{hotel.propertyName}</h3>
                  <div className="trending-text-line">
                    <p>
                      <i className="bi bi-geo-alt-fill"></i> {hotel.location.city}, {hotel.state}
                    </p>
                  </div>
                  <div className="trending-text-line2">
                    <p>
                      <img src={size1} alt="Guest capacity" /> {hotel.guestCapacity}
                    </p>
                    <p>
                      <img src={size2} alt="Total rooms" /> {hotel.totalRooms}
                    </p>
                    <p>
                      <img src={size} alt="Pool size" /> {hotel.poolSize.length} X {hotel.poolSize.breadth}
                    </p>
                  </div>
                  <div className="trending-text-line">
                    {hotel.rates.length > 0 && (
                      <h2>
                        ₹ {hotel.rates[0].weekend} <span>/ night</span>
                      </h2>
                    )}
                    <button>Book Now</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}

export default AllCollections;

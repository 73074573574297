import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

function Collections() {
  const [collectionsByCategory, setCollectionsByCategory] = useState({});
  const [error, setError] = useState('');
  const [filteredCollections, setFilteredCollections] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true); // State to manage loader visibility
     const navigate = useNavigate();
   
   const handleNavigation = (path) => {
     window.scrollTo({ top: 0, behavior: 'smooth' });
     navigate(path);
   };

  const sortCollections = (collections) => {
    return collections.sort((a, b) => a.collectionName.localeCompare(b.collectionName));
  };

  useEffect(() => {
    const fetchCollections = async () => {
      setLoading(true); // Show loader
      try {
        const response = await axios.get('https://tripolo-backend.vercel.app/api/collection/all');
        if (response.status === 200) {
          const collections = response.data.data;

          const groupedCollections = collections.reduce((acc, collection) => {
            const categoryName = collection.category.categoryName;
            if (!acc[categoryName]) {
              acc[categoryName] = [];
            }
            acc[categoryName].push(collection);
            return acc;
          }, {});

          Object.keys(groupedCollections).forEach((categoryName) => {
            groupedCollections[categoryName] = sortCollections(groupedCollections[categoryName]);
          });

          setCollectionsByCategory(groupedCollections);
          setFilteredCollections(groupedCollections);
        }
      } catch (error) {
        setError('Failed to fetch collections. Please try again.');
      } finally {
        setLoading(false); // Hide loader
      }
    };

    fetchCollections();
  }, []);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    const newFilteredCollections = Object.keys(collectionsByCategory).reduce((acc, categoryName) => {
      const filteredCategory = collectionsByCategory[categoryName].filter(collection =>
        collection.collectionName.toLowerCase().includes(query.toLowerCase())
      );
      if (filteredCategory.length > 0) {
        acc[categoryName] = sortCollections(filteredCategory);
      }
      return acc;
    }, {});

    setFilteredCollections(newFilteredCollections);
  };

  const goToCollectionDetails = (collectionId) => {
    navigate(`/collection-details/${collectionId}`);
  };

  return (
    <>
      <Navbar />
      <div className='col-banner'>
        <div className='search-bar search-bar2'>
          <h2>All Collections</h2>
          <div className='search-bar-div'>
            <input 
              placeholder='Search Collection' 
              value={searchQuery} 
              onChange={handleSearch} 
            />
            <i className="bi bi-search"></i>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      ) : (
        <div className='collection-rad'>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {Object.keys(filteredCollections).length > 0 ? (
            Object.entries(filteredCollections).map(([categoryName, collections]) => (
              <div key={categoryName}>
                <div className='bar-heading'>
                  <p>{categoryName}</p>
                  {/* <button>See More</button> */}
                </div>
                <div className='coll'>
                  {collections.map((collection) => (
                    <div 
                      key={collection._id} 
                      className='col-card' 
                      style={{ 
                        backgroundImage: `url(${collection.image})`, 
                        backgroundSize: 'cover', 
                        backgroundPosition: 'center' 
                      }}
                      onClick={() => goToCollectionDetails(collection._id)}
                    >
                      <div className='overlay'>
                        <p>{collection.collectionName}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <p>No collections available.</p>
          )}
        </div>
      )}
      <Footer />
    </>
  );
}

export default Collections;

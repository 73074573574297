import React, { useRef, useState, useEffect } from "react";

import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import img from "../Assets/rb_87230.png";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";

function Contact() {
  const navigate = useNavigate();
  const form = useRef();

  const handleNavigation = (path) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  const sendEmail = (e) => {
    e.preventDefault();

    console.log("Form Data: ", new FormData(form.current)); // Debug form data

    emailjs
      .sendForm(
        "service_73n3tpu",
        "template_17vxybl",
        form.current,
        "_5uAG9-tH3xeEGuXd"
      )
      .then(
        () => {
          alert(
            "Thank you for getting in touch. Our team will contact you shortly."
          );
          form.current.reset();
        },
        (error) => {
          alert("Oops! Something went wrong. Please try again.");
          console.error(error);
        }
      );
  };
  return (
    <>
      <Navbar />
      <div className="banner banner2">
        <h2>
          We're here to help
          <br />
          let's connect!
        </h2>
      </div>
      <div className="explore">
        <div className="contact-us">
          <div className="contact-us-left">
            <img src={img} />
          </div>
          <div className="contact-us-right">
            <form ref={form} onSubmit={sendEmail} className="contact-form">
              <h2>let's connect!</h2>
              <br />
              <br />
              <label>Name</label>
              <br />
              <input required name="user_name" placeholder="Enter Name" />
              <br />
              <br />

              <label>Mobile No.</label>
              <br />
              <input
                required
                type="text"
                name="user_mobile"
                placeholder="Enter Mobile No."
              />
              <br />
              <br />

              <label>Email</label>
              <br />
              <input
                required
                type="email"
                name="user_email"
                placeholder="Enter Email"
              />
              <br />
              <br />

              <label>Message</label>
              <br />
              <textarea name="user_message" placeholder="Enter Message" />
              <br />
              <br />
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;

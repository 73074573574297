import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Loader from "../Components/Loader"; // Assuming you have a Loader component

function LocationCollections() {
  const { locationId } = useParams();
  const [collectionsByCategory, setCollectionsByCategory] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Loading state
     const navigate = useNavigate();
   
   const handleNavigation = (path) => {
     window.scrollTo({ top: 0, behavior: 'smooth' });
     navigate(path);
   };

  useEffect(() => {
    axios
      .get(
        `https://tripolo-backend.vercel.app/api/advance/collection/bylocation/${locationId}`
      )
      .then((response) => {
        const data = response.data.data;
        const groupedCollections = data.reduce((acc, category) => {
          const sortedCollections = category.collections.sort((a, b) =>
            a.collectionName.localeCompare(b.collectionName)
          );
          acc[category.categoryName] = sortedCollections;
          return acc;
        }, {});
        setCollectionsByCategory(groupedCollections);
      })
      .catch((error) => {
        setError("Failed to load collections.");
        console.error("Error fetching collections:", error);
      })
      .finally(() => {
        setIsLoading(false); // Stop loading after data is fetched
      });
  }, [locationId]);

  const filteredCollections = Object.entries(collectionsByCategory).reduce(
    (acc, [category, collections]) => {
      const filtered = collections.filter((collection) =>
        collection.collectionName
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
      if (filtered.length) acc[category] = filtered;
      return acc;
    },
    {}
  );

  const handleCollectionClick = (collectionId) => {
    navigate(
      `/search-results/location/${locationId}/collection/${collectionId}`
    );
  };

  return (
    <>
      <Navbar />
      <div>
        {/* Banner Section */}
        <div className="col-banner">
          <div className="search-bar search-bar2">
            <h2>All Collections</h2>
            <div className="search-bar-div">
              <input
                placeholder="Search Collection"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <i className="bi bi-search"></i>
            </div>
          </div>
        </div>

        {/* Loader */}
        {isLoading ? (
          <Loader /> // Replace this with your loader component or a spinner
        ) : (
          <div className="collection-rad">
            {error && <p style={{ color: "red" }}>{error}</p>}

            {Object.keys(filteredCollections).length > 0 ? (
              Object.entries(filteredCollections).map(
                ([categoryName, collections]) => (
                  <div key={categoryName}>
                    <div className="bar-heading">
                      <p>{categoryName}</p>
                      <button>See More</button>
                    </div>
                    <div className="coll">
                      {collections.map((collection) => (
                        <div
                          key={collection.collectionId}
                          className="col-card"
                          style={{
                            backgroundImage: `url(${collection.image})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleCollectionClick(collection.collectionId)
                          }
                        >
                          <div className="overlay">
                            <p>{collection.collectionName}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )
            ) : (
              <p>No collections available.</p>
            )}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default LocationCollections;

import React, { useState, useEffect } from "react";
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const [activeTab, setActiveTab] = useState(1);
  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };
      const navigate = useNavigate();
    
    const handleNavigation = (path) => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      navigate(path);
    };
  return (
    <>
        <Navbar/>
        <div className='search-bar'>
        <h2>Dashboard</h2>
     <div className='search-bar-div'>
     <input placeholder='Search Collectoin' />
     <i class="bi bi-search"></i>
     </div>
        </div>
        <div className=''>
        <div className="tab-container">
                <div className="tabs">
                  <button
                    className={activeTab === 1 ? "tab-btn active" : "tab-btn"}
                    onClick={() => handleTabChange(1)}>
                    Leads
                  </button>
                  <button
                    className={activeTab === 2 ? "tab-btn active" : "tab-btn"}
                    onClick={() => handleTabChange(2)}>
                    Properties
                  </button>
                  
               
                </div>

                <br />
                <br />
                <div className="tab-content">
                  {activeTab === 1 && (
                    <>
                    <table class="table">
  <thead>
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">Name</th>
      <th scope="col">Contact</th>
      <th scope="col">Email</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Strix Digital</td>
      <td>+91-9874563210</td>
      <td>strix@gmail.com</td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Strix Digital</td>
      <td>+91-9874563210</td>
      <td>strix@gmail.com</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>Strix Digital</td>
      <td>+91-9874563210</td>
      <td>strix@gmail.com</td>
    </tr>
  </tbody>
</table>
                    </>
                  )}
                  {activeTab === 2 && (
                    <>
                    <table class="table">
  <thead>
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">Property Name</th>
      <th scope="col">Location</th>
      <th scope="col">Booking</th>
      <th scope="col">Email</th>

    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Strix Digital</td>
      <td>Indore</td>

      <td>Book</td>
      <td>strix@gmail.com</td>
      

    </tr>
    <tr>
      <th scope="row">1</th>
      <td>Strix Digital</td>
      <td>Indore</td>

      <td>Book</td>
      <td>strix@gmail.com</td>
      

    </tr>
    <tr>
      <th scope="row">1</th>
      <td>Strix Digital</td>
      <td>Indore</td>

      <td>Book</td>
      <td>strix@gmail.com</td>
      

    </tr>
  </tbody>
</table>
                    </>
                  )}

                 
                </div>
              </div>
        </div>

        <Footer/>

    </>
  )
}

export default Dashboard
import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../Components/Navbar";
import icon1 from "../Assets/fort-awesome-svgrepo-com 1.png";
import img2 from "../Assets/div.featureCard__image.png";
import img3 from "../Assets/image 3.png";
import img4 from "../Assets/Group 25.png";
import img5 from "../Assets/Container.png";
import img7 from "../Assets/why.png";
import icon2 from "../Assets/icon (1).png";
import icon3 from "../Assets/icon (2).png";
import icon4 from "../Assets/icon (3).png";
import icon5 from "../Assets/icon2 (1).png";
import icon6 from "../Assets/icon2 (2).png";
import icon7 from "../Assets/icon2 (3).png";
import icon8 from "../Assets/icon2 (4).png";
import profile from "../Assets/Group 26.png";
import Footer from "../Components/Footer";
import srcicon from "../Assets/div.searchFormItem__icon.png";
import srcicon2 from "../Assets/buld.png";
import srcicon3 from "../Assets/guest.png";
import HotelCard from "../Components/HotelCard";
import ExploreCards from "../Components/ExploreCards";
import SliderBanner from "../Components/SliderBanner";
import { Link, useNavigate } from "react-router-dom";
import SelectedCol from "./SelectedCol";
import PropertyCard from "../Components/PropertyCard";

function Home() {
  // const [collections, setCollections] = useState([]);
  const [locations, setLocations] = useState([]);
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCollection, setSelectedCollection] = useState("");
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(path);
  };
  // Fetch locations
  useEffect(() => {
    axios
      .get("https://tripolo-backend.vercel.app/api/advance/location/collection")
      .then((response) => {
        console.log(response);
        setLocations(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching locations:", error);
      });
  }, []);

  // Fetch collections
  // useEffect(() => {
  //   const fetchCollections = async () => {
  //     try {
  //       const response = await axios.get(
  //         "https://tripolo-backend.vercel.app/api/collection/all"
  //       );
  //       if (response.data && response.data.data) {
  //         setCollections(response.data.data);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching collections:", error);
  //     }
  //   };
  //   fetchCollections();
  // }, []);

  const handleCollectionChange = (e) => {
    setSelectedCollection(e.target.value);
  };

  // console.log(selectedLocation)

  const handleSelectChange = (e) => {
    const locationId = e.target.value;
    setSelectedLocation(locationId);

    // Filter collections for the selected location
    const selectedLocationData = locations.find(
      (loc) => loc.location._id === locationId
    );

    if (selectedLocationData) {
      setFilteredCollections(selectedLocationData.collections || []);
    } else {
      setFilteredCollections([]);
    }
    setSelectedCollection("");
  };

  const handleSearch = () => {
    if (selectedLocation && selectedCollection) {
      const locationId = selectedLocation;
      const collectionId = selectedCollection;

      if (locationId && collectionId) {
        // Navigate to the search results page with location and collection IDs in the URL
        navigate(
          `/search-results/location/${locationId}/collection/${collectionId}`
        );
      } else {
        alert("Please select a valid location and collection.");
      }
    } else {
      alert("Please select both location and collection.");
    }
  };
  return (
    <>
      <Navbar />
      <SliderBanner />
      {/* <div className='banner'>
      <h2>Find Next PlaceTo Visit</h2>
      <br/>
      <p>Discover amzaing places at exclusive deals.Eat, Shop, Visit
      interesting places around the world.</p>
      <br/>
    
      <button>Book Now</button>
      </div> */}
      <br /> <br />
      <div className="explore">
        <div className="explore-heading">
          <div className="srcicon">
            <img src={srcicon} />
            <div>
              <h6>Where to Next</h6>
              <div className="selector-container">
                <select
                  value={selectedLocation}
                  onChange={(e) => {
                    handleSelectChange(e);
                  }}
                  className="basic-selector">
                  <option value="" disabled>
                    Select a location...
                  </option>
                  {locations.map((location) => (
                    <option key={location._id} value={location._id}>
                      {location?.location?.city}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="srcicon">
            <img src={srcicon2} />
            <div>
              <h6>Collections</h6>
              <select
                id="basic-selector"
                className="basic-selector"
                value={selectedCollection}
                onChange={handleCollectionChange}>
                <option value="" disabled>
                  Select a collection...
                </option>
                {filteredCollections &&
                  filteredCollections?.length > 0 &&
                  filteredCollections?.map((collection) => (
                    <option
                      key={collection.collectionId}
                      value={collection.collectionId}>
                      {collection.collectionName}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="srcicon">
            <img src={srcicon3} />
            <div>
              <h6>Guests</h6>
              <input placeholder="No. of Guests" />
            </div>
          </div>
          <button onClick={handleSearch}>Search</button>
        </div>
        <br />
        <br />

        <ExploreCards />
      </div>
      <div className="trending">
        <div className="collection-heading">
          <h2>Trending This Season</h2>
          {/* <p>See all</p> */}
        </div>
        <br />

        {/* <div className='trending-cards'>
<div>

<div className='trending-card'>
<img src={img5}/>
<div className='trending-text'>
<h3>California Sunset/Twilight
Boat Cruise</h3>
<br/>
<div className='trending-text-line'>
  <p > <i class="bi bi-geo-alt-fill"></i>Manchester, England</p>
  <p className='stars'><i class="bi bi-star-fill"></i>
  <i class="bi bi-star-fill"></i>
  <i class="bi bi-star-fill"></i>
  <i class="bi bi-star-fill"></i>
  <i class="bi bi-star-fill"></i></p>
</div>

<br/>
<br/>
<br/>
<div className='trending-text-line'>
 <h2>$48.25 <span>/ person</span></h2>
 <button>Book Now</button>
</div>


</div>
  
  </div>
</div>




</div> */}
        <HotelCard />
      </div>
      <div className="collection">
        <div className="collection-heading">
          <h2>Collection For You</h2>
          <Link
            style={{ textDecoration: "none", color: "white" }}
            to="/collections">
            <button
              className="seeall-btn"
              style={{ textDecoration: "none", color: "white" }}>
              See all
            </button>
          </Link>
        </div>
        <br />
        <SelectedCol />
        {/* <div className='collection-grid'>
<div className='collection-grid-left'>
<div className='collection-grid-left1'>
  <img src={img2}/>
  <img src={img2}/>
</div>
<div className='collection-grid-left1'>
  <img src={img3}/>

</div>
</div>
<div className='collection-grid-right'>
<div className='collection-grid-left1'>
  <img src={img2}/>
  <img src={img2}/>
</div>
<div className='collection-grid-left1'>
  <img src={img3}/>

</div>
</div>
</div> */}
      </div>
      <div className="ourfetured">
        <div className="collection-heading">
          <h2>Our Featured Tours</h2>
          {/* <p>See all</p> */}
        </div>
        <br />
        <PropertyCard />
      </div>
      {/* <div className='grab-offer'>
<div className='grab-offer-left'>
<h2>Grab up to <span>35% off</span><br/>
on your favorite<br/>
Destination</h2>
<p>Limited time offer, don't miss the opportunity</p>
<br/>
<button>Book Now</button>

</div>
<div className='grab-offer-right'>
<img src={img4}/>

</div>
</div> */}
      <div className="Whychoose">
        <div className="Whychoose-left">
          <h2>Why choose Tripolo</h2>
          <br />

          <div className="Whychoose-text">
            <img src={icon2} />
            <div>
              <h4>Ultimate flexibility</h4>
              <p>
                You'in full control, direct conversation with property
                owners
              </p>
            </div>
          </div>
          <br />
   

          <div className="Whychoose-text">
            <img src={icon3} />
            <div>
              <h4>Quality at our core</h4>
              <p>High quality standards, Handpicked properties.</p>
            </div>
          </div>
          <br />
       

          <div className="Whychoose-text">
            <img src={icon4} />
            <div>
              <h4>Memorable experiences</h4>
              <p>
                Browse and book tours and activities so
                <br />
                incredible.
              </p>
            </div>
          </div>
        </div>
        <div className="Whychoose-right">
          <img src={img7} />
        </div>
      </div>
      <div className="count">
        <div className="count-card">
          <img src={icon7} />
          <br />
          <h2>20+</h2>
          <p>Locations</p>
        </div>
        <div className="count-card">
          <img src={icon8} />
          <br />
          <h2>300+</h2>
          <p>Properties</p>
        </div>
        <div className="count-card">
          <img src={icon5} />
          <br />
          <h2>10K+</h2>
          <p>Happy Clients</p>
        </div>
        <div className="count-card">
          <img src={icon6} />
          <br />
          <h2>4.9</h2>
          <p>Ratings</p>
        </div>
      </div>
      {/* <div className='testimonials'>
<div className='testimonials-slid'>
  <h4>Customer Reviews</h4>
  <br/>
  <br/>
  <br/>
  <br/>
  <img src={profile}/>
  <br/>
  <h6>Excellent Service!</h6>
  <br/>
  <h5>I had an amazing experience with this company. The service was
  <br/>

top-notch, and the staff was incredibly friendly. I highly
<br/>

recommend them!</h5>
<br/>

<h3>John Smith</h3>
<p>Traveler</p>
</div>

</div> */}
      <Footer />
    </>
  );
}

export default Home;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import size from "../Assets/Screenshot_2024-11-28_123540-removebg-preview.png";
import size1 from "../Assets/person.png";
import size2 from "../Assets/room.png";

function HotelCard() {
  const [hotels, setHotels] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHotels = async () => {
      try {
        const response = await axios.get(
          "https://tripolo-backend.vercel.app/api/selected-hotel/valid"
        );
        if (response.status === 200) {
          const shuffledHotels = shuffleArray(response?.data?.data?.hotels);
          setHotels(shuffledHotels);
        }
      } catch (error) {
        console.error("Error fetching hotels:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchHotels();
  }, []);

  const shuffleArray = (array) => {
    return array
      .map((item) => ({ ...item, sortKey: Math.random() }))
      .sort((a, b) => a.sortKey - b.sortKey)
      .map(({ sortKey, ...rest }) => rest);
  };

  const handleHotelClick = (id) => {
    navigate(`/collectiondetail/${id}`);
  };

  return (
    <div className="trending-cards">
      {loading ? (
        <div className="loader"></div>
      ) : (
        hotels.map((hotel) => (
          <div key={hotel._id}>
            <div
              className="trending-card trending-card010"
              onClick={() => handleHotelClick(hotel._id)}
            >
              <img
                src={hotel.images.profile}
                alt={hotel.propertyName}
                className="hotel-image"
              />
              <div className="trending-text">
                <h3>{hotel.propertyName}</h3>
                <div className="trending-text-line">
                  <p>
                    <i className="bi bi-geo-alt-fill"></i> {hotel.city}
                  </p>
                </div>
                <div className="trending-text-line2">
                  <p>
                    <img src={size1} alt="Guest capacity" /> {hotel.guestCapacity}
                  </p>
                  <p>
                    <img src={size2} alt="Total rooms" /> {hotel.totalRooms}
                  </p>
                  <p>
                    <img src={size} alt="Pool size" /> {hotel.poolSize.length} X{" "}
                    {hotel.poolSize.breadth}
                  </p>
                </div>
                <div className="trending-text-line">
                  {hotel.rates.length > 0 && (
                    <h2>
                      ₹ {hotel.rates[0].weekend} <span>/ night</span>
                    </h2>
                  )}
                  <button>Book Now</button>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default HotelCard;

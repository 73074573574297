import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../Assets/WhatsApp_Image_2024-12-24_at_6.41.59_PM-removebg-preview.png';
import icon from '../Assets/help.svg.png';
import icon2 from '../Assets/join.svg.png';
import icon3 from '../Assets/msg.svg.png';

function Footer() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(path);
  };

  return (
    <div className='footer'>
      <img className='navlogo' src={logo} alt="Logo" />
      <br /><br /><br />
      <div className='footer-links'>
        <ul>
          Dive into local recommendations for a truly<br />
          authentic experience.
          <li><i className="bi bi-geo-alt-fill"></i> Mumbai, Maharashtra 421202</li>
          <li><i className="bi bi-clock"></i> Hours: 10:00 am - 18:00 pm, Mon - Sat</li>
          <li><i className="bi bi-envelope-fill"></i> Info@tripolostays.com</li>
        </ul>
        <ul>
          Company
          <li onClick={() => handleNavigation('/')}>Home</li>
          <li onClick={() => handleNavigation('/collections')}>Collection</li>
        </ul>
        <ul>
          Quick Links
          {/* <li onClick={() => handleNavigation('/contact')}>Contact Us</li> */}
          <li onClick={() => handleNavigation('/privacypolicy')}>Privacy Policy</li>
          <li onClick={() => handleNavigation('/termancondition')}>Term and Condition</li>
        </ul>
        <ul className='subs-div'>
        Follow us
         <li>
         <a style={{ color: 'white' }} href='https://www.instagram.com/tripolo_stays'><i className="bi bi-instagram" style={{ color: 'white' }}></i></a>
         <a  href='https://youtube.com/@tripolostays?si=TTYX4d0G_-R2tGzj' style={{ color: 'white' }}><i className="bi bi-youtube" style={{ color: 'white' }}></i></a>
         </li>
          
        </ul>
      </div>
      <br /><br />
      <div className='contacts'>
        <div className='contacts-div'>
          <img src={icon} alt="Help Icon" />
          <div>
            <p>Need help? Call us</p>
            <h3>+91-99878 56356</h3>
          </div>
        </div>
        <div className='contacts-div'>
          <img src={icon2} alt="Join Icon" />
          <h3>Join Tripolo Affiliate<br />Program Today!</h3>
        </div>
        <div className='contacts-div'>
          <img src={icon3} alt="Message Icon" />
          <h3>Send us a message</h3>
          <p>Contact our agents about your booking, and we'll reply as soon as possible.</p>
        </div>
      </div>
      <br /><br />
      <div className='reserved'>
        <p>© 2024 Tripolo Stays. Developed by <a style={{ color: 'inherit' }} href='https://www.strixdigital.in'>Strix Digital</a>. Powered by Villa Chalo</p>
        {/* <div className='Followus'>
          <p>Follow us </p>
          <a style={{ color: 'inherit' }} href='https://www.instagram.com/tripolo_stays'><i className="bi bi-instagram"></i></a>
          <a href='https://youtube.com/@tripolostays?si=TTYX4d0G_-R2tGzj' style={{ color: 'inherit' }}><i className="bi bi-youtube"></i></a>
        </div> */}
      </div>
    </div>
  );
}

export default Footer;

import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import login from '../Assets/rb_2413.png';

function VenderLogin() {
  const [email, setEmail] = useState(''); // Replaced userID with email
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [emailForOtp, setEmailForOtp] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false); // Toggles between Login and Forgot Password
  const [step, setStep] = useState(1); // Step 1: OTP send, Step 2: OTP verify and reset password

    const navigate = useNavigate();
  
  const handleNavigation = (path) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(path);
  };

  // Handle login
  const handleLogin = async () => {
    try {
      const response = await axios.post('https://tripolo-backend.vercel.app/api/user/login', {
        email: email, // Updated key to use email
        password: password,
      });

      if (response.status === 200) {
        const { user, token } = response.data.data;

        setSuccessMessage('Logged in successfully!');
        setErrorMessage('');
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', token);
        navigate(-1);
      }
    } catch (error) {
      setErrorMessage('Invalid Email or Password. Please try again.');
      setSuccessMessage('');
    }
  };

  // Handle OTP sending
  const handleSendOtp = async () => {
    try {
      const response = await axios.post('https://tripolo-backend.vercel.app/api/user/forgot/otpsend', {
        email: emailForOtp,
      });

      if (response.status === 200) {
        setSuccessMessage('OTP sent successfully to your email!');
        setErrorMessage('');
        setStep(2); // Move to OTP verification step
      }
    } catch (error) {
      setErrorMessage('Failed to send OTP. Please check the email.');
      setSuccessMessage('');
    }
  };

  // Handle OTP verification and password reset
  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post('https://tripolo-backend.vercel.app/api/user/forgot/otpverify', {
        email: emailForOtp,
        password: newPassword,
        otp: otp,
      });

      if (response.status === 200) {
        setSuccessMessage('Password reset successfully!');
        setErrorMessage('');
        setShowForgotPassword(false); // Toggle back to Login
        setStep(1); // Reset to initial step
      }
    } catch (error) {
      setErrorMessage('Invalid OTP or failed to reset password. Please try again.');
      setSuccessMessage('');
    }
  };

  return (
    <>
      <Navbar />
      <div className="login-page">
        <div className="login-page-left">
          <img src={login} alt="Login" />
        </div>
        <div className="login-page-right">
          <div className="login-page-right-form">
            <h3>{showForgotPassword ? 'Forgot Password' : 'Login'}</h3>
            <br />
            <br />

            {showForgotPassword ? (
              step === 1 ? (
                <>
                  <label>Email</label>
                  <input
                    placeholder="Enter Your Email"
                    value={emailForOtp}
                    onChange={(e) => setEmailForOtp(e.target.value)}
                  />
                  <br />
                  <button onClick={handleSendOtp}>Send OTP</button>
                </>
              ) : (
                <>
                  <label>OTP</label>
                  <input
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                  <br />
                  <label>New Password</label>
                  <input
                    type="password"
                    placeholder="Enter New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <br />
                  <button onClick={handleVerifyOtp}>Reset Password</button>
                </>
              )
            ) : (
              <>
                <label>Email</label>
                <input
                  placeholder="Enter Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <br />
                <label>Password</label>
                <input
                  type="password"
                  placeholder="Enter Your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <br />
                <br />
                <button onClick={handleLogin}>Login</button>
                <br />

                <p>
                  <span
                    style={{ color: 'black', cursor: 'pointer' , float:'right'}}
                    onClick={() => {
                      setShowForgotPassword(true);
                      setStep(1); // Reset step to 1
                    }}
                  >
                    Forgot Password?
                  </span>
                </p>
                <br />
                <Link to='/signup'>

                <p style={{textAlign:'center' , textDecoration:'nonw',color:'black'}}>Create new account</p>
                </Link>


              </>
            )}

            {showForgotPassword && (
              <p>
                <span
                  style={{ color: 'blue', cursor: 'pointer' }}
                  onClick={() => setShowForgotPassword(false)}
                >
                  Back to Login
                </span>
              </p>
            )}

            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default VenderLogin;

import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { useNavigate } from 'react-router-dom';

function PrivacyPolicy() {
        const navigate = useNavigate();
      
      const handleNavigation = (path) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        navigate(path);
      };
  return (
    <>
        <Navbar/>
        <div className='banner banner2'>
      <h2>Understand the rules and guidelines for using our services.</h2>
     
      </div>
      <div className='explore explore tnc'>
      <h2 style={{textAlign:'center'}}>Privacy Policy</h2>
      <br/>
      <br/>
        
   
      <h3>1. Introduction</h3>
    <p>
        We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data.
    </p>

    <h3>2. Information We Collect</h3>
    <p>
        We may collect personal information that you provide, such as your name, email address, phone number, and any other information you choose to provide when you use our services.
    </p>

    <h3>3. How We Use Your Information</h3>
    <p>
        We use the information we collect to:
    </p>
    <ul>
        <li>Provide and improve our services.</li>
        <li>Communicate with you regarding updates, promotions, and support.</li>
        <li>Personalize your experience on our website.</li>
        <li>Ensure compliance with applicable laws and regulations.</li>
    </ul>

    <h3>4. Cookies and Tracking</h3>
    <p>
        We may use cookies and similar tracking technologies to enhance your browsing experience and gather information about your interaction with our website. You can disable cookies in your browser settings, but it may affect the functionality of our website.
    </p>

    <h3>5. Data Security</h3>
    <p>
        We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, or misuse. However, no data transmission over the internet is completely secure, and we cannot guarantee absolute security.
    </p>

    <h3>6. Sharing Your Information</h3>
    <p>
        We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except when necessary to provide our services or comply with the law.
    </p>

    <h3>7. Changes to This Privacy Policy</h3>
    <p>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page with the updated date. Your continued use of our website after any changes signifies your acceptance of the updated policy.
    </p>

    <h3>8. Contact Us</h3>
    <p>
        If you have any questions or concerns about our Privacy Policy, please contact us at <a href="mailto:Info@tripolostays.com" style={{textDecoration:'none' , color:'#fb7712'}}>Info@tripolostays.com</a>
    </p>
      </div>

        <Footer/>
    </>
  )
}

export default PrivacyPolicy
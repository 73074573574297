import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import size from '../Assets/Screenshot_2024-11-28_123540-removebg-preview.png';
import size1 from '../Assets/person.png';
import size2 from '../Assets/room.png';
import Loader from '../Components/Loader'; // Assuming you have a Loader component

function Hotel() {
  const [collections, setCollections] = useState([]);
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Loading state





  const { collectionId } = useParams();
    const navigate = useNavigate();
  
  const handleNavigation = (path) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(path);
  };

  useEffect(() => {
    const fetchCollectionDetails = async () => {
      try {
        const response = await axios.get(`https://tripolo-backend.vercel.app/api/hotel/collection/${collectionId}`);
        if (response.status === 200) {
          setCollections(response.data.data);
          setFilteredCollections(response.data.data);
        }
      } catch (error) {
        setError('Failed to fetch collection details.');
      } finally {
        setIsLoading(false); // Stop loading after the API call
      }
    };

    fetchCollectionDetails();
  }, [collectionId]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = collections.filter(collection =>
        collection.propertyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        collection.location.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
        collection.state.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredCollections(filtered);
    } else {
      setFilteredCollections(collections);
    }
  }, [searchQuery, collections]);

  const handleCardClick = (id) => {
    navigate(`/collectiondetail/${id}`);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <Navbar />

      <div className='col-banner col-banner2'>
        <div className='search-bar search-bar2'>
          <h2>All Collections</h2>
          <div className='search-bar-div'>
            <input 
              value={searchQuery} 
              onChange={handleSearchChange} 
              placeholder='Search Collection' 
            />
            <i className="bi bi-search"></i>
          </div>
        </div>
      </div>

      {isLoading ? (
        <Loader /> // Show loader while loading
      ) : error ? (
        <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>
      ) : (
        <div className='trending-cards2 trending-cards3'>
          <div className='az'>
            {filteredCollections.map((collection) => (
              <div 
                key={collection._id} 
                className='trending-card trending-card2' 
                onClick={() => handleCardClick(collection._id)}
                style={{ cursor: 'pointer' }}
              >
                <img src={collection.images.profile} alt={collection.propertyName} />
                <div className='trending-text'>
                  <h3>{collection.propertyName}</h3>
                  <div className='trending-text-line'>
                    <p>
                      <i className="bi bi-geo-alt-fill"></i> {collection.location.city}, {collection.state}
                    </p>
                  </div>
                  <div className='trending-text-line2'>
                    <p><img src={size1} alt="Guest capacity" /> {collection.guestCapacity}</p>
                    <p><img src={size2} alt="Total rooms" /> {collection.totalRooms}</p>
                    <p><img src={size} alt="Pool size" /> {collection.poolSize.length} X {collection.poolSize.breadth}</p>
                  </div>
                  <div className='trending-text-line'>
                    <h2>₹ {collection.rates[0].weekend} <span>/ night</span></h2>
                    <button>Book Now</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <Footer />
    </>
  );
}

export default Hotel;

import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import logo from '../Assets/WhatsApp_Image_2024-12-24_at_6.41.59_PM-removebg-preview.png';

function Navbar() {
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [user, setUser] = useState(null); 
  const [isProfileOpen, setIsProfileOpen] = useState(false); 
  const toggleDivRef = useRef(null);
  const profileRef = useRef(null);
  const navigate = useNavigate();


  const handleNavigation = (path) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(path);
  };
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user')); 
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  const handleClickOutside = (event) => {
    if (toggleDivRef.current && !toggleDivRef.current.contains(event.target)) {
      setIsDivVisible(false);
    }
    if (profileRef.current && !profileRef.current.contains(event.target)) {
      setIsProfileOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('user'); 
    localStorage.removeItem('token'); 
    setUser(null); 
    setIsProfileOpen(false);
    navigate('/'); 
  };

  return (
    <>
      <nav>
     

        <Link to='/'>
          <img src={logo} className='navlogo' alt="Logo" />
        </Link>
      

        <ul>
          <NavLink exact className='none1' activeClassName='active' to='/'><li>Home</li></NavLink>
          <NavLink className='none1' activeClassName='active' to='/collections'><li>Collections</li></NavLink>
          <NavLink className='none1' activeClassName='active' to='/contact'><li>Contact Us</li></NavLink>
          <NavLink className='none1' activeClassName='active' to='/privacypolicy'><li>Privacy Policy</li></NavLink>
          <NavLink className='none1' activeClassName='active' to='/termancondition'><li>Terms and Conditions</li></NavLink>

          {!user ? (
            <>
            <Link to='/login'>

            <i style={{color:'black'}}
                className="bi bi-person-circle profile-icon"
                
              ></i>
            </Link>
            </>
          ) : (
            <div ref={profileRef} className="profile-section">
              <i 
                className="bi bi-person-circle profile-icon"
                onClick={() => setIsProfileOpen(!isProfileOpen)}
              ></i>
              {isProfileOpen && (
                <div className="profile-dropdown">
                  <p>Hello, {user.name}</p>
                  <button onClick={handleLogout}>Logout</button>
                </div>
              )}
            </div>
          )}
         
          <i
            style={{ color: 'black', cursor: 'pointer' }}
            className="bi bi-list"
            onClick={() => setIsDivVisible(!isDivVisible)}
          ></i>
        </ul>
      </nav>

      {isDivVisible && (
        <div className="toggleDiv-ops">
          <div className="toggleDiv" ref={toggleDivRef}>
            <ul>
              <NavLink exact className="none" activeClassName="active" to="/"><li>Home</li></NavLink>
              <NavLink className='none' activeClassName="active" to='/collections'><li>Collections</li></NavLink>
              <NavLink className='none' activeClassName="active" to='/contact'><li>Contact Us</li></NavLink>
              <NavLink className='none' activeClassName="active" to='/privacypolicy'><li>Privacy Policy</li></NavLink>
              <NavLink className='none' activeClassName="active" to='/termancondition'><li>Terms and Conditions</li></NavLink>
              {!user ? (
                <>
                  <NavLink className='none1' activeClassName='active' to='/login'><button className='lg-btn'>Login</button></NavLink>
                  <br/>
                  <br/>
                  <NavLink className='none1' activeClassName='active' to='/signup'><li><button className='lg-btn2'>Signup</button></li></NavLink>
                </>
              ) : null}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
